@import "styles/main.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  height: 100%;
  background-color: #fff;
}

.page {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  touch-action: none;

  @include onLaptop {
    overflow: auto;
  }
}

#root {
  min-width: 360px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1 auto;
}

html, input, button {
  font-family: 'Ubuntu', sans-serif, -apple-system;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #262E36 inset !important;
    -webkit-text-fill-color: #fff;
}
