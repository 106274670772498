.swiper {
  width: 100%;
  max-width: 476px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

@media (min-width: 1240px) {
  .swiper {
    width: 50%;
  }
}